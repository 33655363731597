const COLORS = {
  white: '#ffffff',
  black: '#000000',
  blackOpacity: 'rgba(0,0,0,0.6)',
  lightBlack: '#222222',
  darkGrey: '#333333',
  darkGrey2: '#323232',
  darkGreyOpacity01: 'rgba(51, 51, 51, 0.1)',
  darkGreyOpacity02: 'rgba(51, 51, 51, 0.25)',
  darkGreyOpacity05: 'rgba(51, 51, 51, 0.5)',
  doveGrey: '#6d6d6d',
  dimGrey: '#747474',
  silver: '#bfbfbf',
  gandalfTheGrey: '#dddddd',
  lightGreyB0: '#b0b0b0',
  lightGreyB1: '#b1b1b1',
  lightGreyD9: '#d9d9d9',
  lightGrey7B: '#7b7b7b',
  lightGreyAE: '#aeaeae',
  whiteSmoke: '#f5f5f5',
  yetAnotherGrey: '#d7d5d5',
  errorRed: '#F83726',
  darkRed: '#EF3340',
  greyLetter: '#4e4e4e',
  borderGrey: '#d7d5d5',
  voucherGrey: '#e7e7e7',
  successGreen: '#39c16c',
  green: '#048C04',
  accentGreen: '#12D45D',
  green700: '#028836',
};

export default COLORS;
