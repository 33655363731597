import * as amplitude from '@amplitude/analytics-browser';

let isInitialized = false;

export const initializeAmplitude = () => {
  if (!isInitialized) {
    amplitude.init(process.env.GATSBY_AMPLITUDE_API_KEY);
    isInitialized = true;
  }
};

export const optInAmplitude = () => {
  if (!isInitialized) {
    amplitude.init(process.env.GATSBY_AMPLITUDE_API_KEY);
    isInitialized = true;
  }
  amplitude.setOptOut(false);
};

export const optOutAmplitude = () => {
  if (isInitialized) {
    amplitude.setOptOut(true);

    const cookies = document.cookie.split('; ');
    cookies.forEach((cookie) => {
      if (cookie.includes('AMP')) {
        // remove the cookie by setting it to expire in the past
        // we need to do this at this time because amplitude sdk needs us to explicitly remove the cookie
        document.cookie = `${cookie}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    });
  }
};

export const logEvent = (eventName, eventProperties) => {
  if (typeof window !== 'undefined') {
    amplitude.track(eventName, eventProperties);
  }
};
