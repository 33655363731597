/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useMemo, useState } from 'react';
import { Switch } from '@lesmills-international/components';
import useFetchRolloverCountries from '../../../hooks/useFetchRolloverCountries';
import {
  Title,
  Container,
  Detail,
  Reminder,
  ErrorMessage,
  SwitchWrapper,
  SavedMessage,
} from './styles';
import { userContext } from '../../../../../gatsby-theme-engagement/src/context';
import useGetCountryCode from '../../../hooks/useGetCountryCode';
import { addErrorInDatadogRum, allowRolloverPreferences } from '../../../utils/utilities';
import { ReactComponent as TickIcon } from '../../../assets/icons/success-tick.svg';
import useUpdateSubscriptionPreference from '../../../hooks/mutation/useUpdateSubscriptionPreference';

type RolloverPageData = Pick<
  Queries.PrismicChangeSubscriptionPageData,
  'rollover_error_message' | 'rollover_title' | 'annual_text' | 'monthly_text'
>;

interface Props {
  prismicData: RolloverPageData;
}

const RolloverPreference = ({ prismicData }: Props) => {
  const { lmodSubscription, refetchUser } = useContext(userContext);
  const {
    annual_text,
    monthly_text,
    rollover_error_message,
    auto_renew_title,
    auto_renew_detail,
    renew_switch_info,
    change_saved,
  } = prismicData;

  const getSubscriptionType = () => {
    let suscriptionType = annual_text;

    if (interval === 12) {
      suscriptionType = annual_text;
    } else if (interval > 1) {
      suscriptionType = `${interval}-${monthly_text}`;
    }

    return suscriptionType;
  };
  const defaultInputValue = lmodSubscription?.default_to_monthly;

  const [renderReminder, setRenderReminder] = useState(defaultInputValue);
  const [renderTick, setRenderTick] = useState(false);

  const { product } = lmodSubscription || {};

  const interval = product?.product_price_point?.interval;

  const { countryCode } = useGetCountryCode();
  const rolloverCountries = useFetchRolloverCountries();

  const [handleUpdateSubscriptionPreference, { error }] = useUpdateSubscriptionPreference({
    onCompleted: () => {
      setRenderTick(true);
      setTimeout(() => {
        setRenderTick(false); // Hide the message after 3 seconds
      }, 3000);
      refetchUser && refetchUser();
    },
    onError: (err) => {
      addErrorInDatadogRum(err);
    },
  });

  const isRolloverAllowed = useMemo(
    () => allowRolloverPreferences(countryCode, product?.product_price_point, rolloverCountries),
    [countryCode, product?.product_price_point, rolloverCountries]
  );

  if (!isRolloverAllowed) {
    return null;
  }
  const rightLabel = getSubscriptionType();
  const defaultLabel = defaultInputValue ? monthly_text : rightLabel;

  const handleOnChange = (text: string) => {
    setRenderTick(false);
    setRenderReminder(text === monthly_text);
    handleUpdateSubscriptionPreference({
      variables: {
        rolloverOption: text === monthly_text,
      },
    });
  };

  return (
    <Container>
      <Title>{auto_renew_title}</Title>
      <Detail>{auto_renew_detail}</Detail>
      {renderTick && (
        <SavedMessage>
          <TickIcon />
          {change_saved}
        </SavedMessage>
      )}
      {error && <ErrorMessage>{rollover_error_message}</ErrorMessage>}
      <SwitchWrapper>
        <Switch
          leftText={monthly_text}
          rightText={getSubscriptionType()}
          getSelectedText={(text: string) => {
            handleOnChange(text);
          }}
          defaultText={defaultLabel}
        />
      </SwitchWrapper>
      {renderReminder && <Reminder>{renew_switch_info}</Reminder>}
    </Container>
  );
};

export default RolloverPreference;
