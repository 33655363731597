import styled from 'styled-components';
import { themes } from '@lesmills-international/components';
import { breakpoint } from '../../../themes/breakpoints';

export const Divider = styled.hr`
  margin: 16px auto;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => themes[theme.themeColor || 'white'].colors.borderSecondary};
  border: none;
`;

export const PaymentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  & button {
    margin-bottom: 100px;
  }
`;

export const WrapperTiering = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & button {
    margin-bottom: 100px;
  }
`;

export const TermsCondition = styled.div`
  max-width: 779px;
  text-align: center;
  font-family: 'Inter Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 24px;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentPrimary};
`;

export const LinkText = styled.div`
  margin-left: 48px;
  margin-top: 20px;
  font-family: 'Inter Regular';
  font-weight: 800;
  font-size: 14px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.contentPrimary};
  text-decoration: underline;
  cursor: pointer;
  text-transform: uppercase;
`;

export const SelectedPlanName = styled.span`
  font-family: 'Inter SemiBold';
`;

export const NewSubWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RightArrowIconWrapper = styled.div`
  margin-bottom: 36px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  a {
    color: blue;
    text-decoration: underline;
  }

  b {
    font-family: 'Inter SemiBold';
  }
`;

export const SubTitle = styled.h2`
  font-family: 'Inter SemiBold';
  font-size: 16px;
  line-height: 40px;
  font-weight: 800;
`;

export const CancellationButton = styled.button`
  font-family: 'Inter SemiBold';
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  pointer: cursor;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentNegative};
`;

export const PricingCardButtonContainer = styled.div`
  display: flex;
  min-width: 375px;
  flex-direction: column;
  justify-content: flex-end;

  ${breakpoint('lg')`
  display: flex;
  min-width: 0;
  align-self: flex-end;
`}
`;
