import { themes } from '@lesmills-international/components';
import styled, { keyframes } from 'styled-components';
import COLORS from '../../../themes/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Detail = styled.h2`
  margin-bottom: 8px;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentPrimary};
  font-family: Inter Regular;
  font-size: 16px;
  line-height: 24px;
`;

export const Title = styled.h2`
  font-family: 'Inter SemiBold';
  font-size: 24px;
  line-height: 40px;
  font-weight: 800;
`;

export const Reminder = styled.div`
  margin-top: 8px;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentSecondary};
  font-family: Inter Regular;
  font-size: 12px;
  line-height: 16px;
`;

export const SwitchWrapper = styled.div`
  margin-top: 8px;
  text-transform: uppercase;
`;

const fadeInOut = keyframes`
  0% {
    opacity: 1; /* Fully visible */
  }
  80% {
    opacity: 1; /* Remain visible */
  }
  100% {
    opacity: 0; /* Fade out */
  }
`;

export const SavedMessage = styled.div`
  margin-left: 2px;
  color: ${COLORS.green700};
  font-family: Inter Regular;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  animation: ${fadeInOut} 3s linear forwards;
`;

export const ErrorMessage = styled.p`
  margin-top: 10px;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentNegative};
  font-family: Inter Regular;
  font-size: 14px;
  line-height: 20px;
`;
