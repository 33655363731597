/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useContext, useState } from 'react';
import { themes, Alert } from '@lesmills-international/components';
import { navigate } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import SubscriptionManagementLayout from '../common/subscriptionManagementLayout';
import { SectionWrapper, RichTextWrapper } from './styles';
import AccountSection from './AccountSection';
import PaymentSection from './PaymentSection';
import SubscriptionSection from './SubscriptionSection';
import RestartSubscriptionPopUp from './RestartSubscriptionPopUp';
import useAccountNotification from '../../hooks/useAccountNotification';
import {
  SignupChannel,
  isFullyCanceled,
  isPendingCancellation,
  isSubscriptionFailed,
} from '../../utils/subscriptions';
import { userContext } from '../../../../gatsby-theme-engagement/src/context';
import EmailPreferencesSection from './EmailPreferencesSection';
import { generateCustomerIOScript } from './utils';
import CancellationModal from './cancellationModal';
import CancellationSuccessModal from './cancellationSuccessModal';
import useValidateMemberPromo from './hooks/useValidateMemberPromo';
import { Tier } from '../../../../../src/type/subscriptions';
import { setLocalStorage } from '../../utils/utilities';
import ALREADY_SEEN_MEMBER_PROMO_OFFER from './constants';
import ROUTES from '../../../../../src/constants/routes';
import MemberPromoModal from './memberPromoModal';

interface Props {
  pageData: Queries.GetAccountOverviewQuery;
  location: any;
}

const AccountOverview = ({ pageData, location }: Props) => {
  const [isRestartSubscrionPopUpOpened, setIsRestartSubscriptionPopUpOpened] = useState(false);
  const [isCancellationModalOpened, setIsCancellationModalOpened] = useState(false);
  const [isMemberPromoModalOpened, setIsMemberPromoModalOpened] = useState(false);
  const [isCancellationSuccessModal, setIsCancellationSuccessModal] = useState(false);
  const [displayCancelSubscriptionError, setDisplayCancelSubscriptionError] = useState(false);

  const { lmodSubscription, error, email, vimeoUserId, tier, addressCountry } =
    useContext(userContext);

  const state = lmodSubscription?.state;
  const productName = lmodSubscription?.product?.name;

  const customerIOScript = generateCustomerIOScript({ email, vimeoUserId, productName });

  const { prismicAccountOverviewPage, prismicMemberPromoPopup, prismicSignupChannels } = pageData;

  const { signup_channel } = prismicSignupChannels?.data as Queries.PrismicSignupChannelsData;

  const {
    subscription_status_on_hold,
    restart_subscription_success,
    restart_subscription_error,
    restart_subscription_pending_cancellation,
    restart_subscription_cancellation,
    account_details_description,
    update_payment_details_title,
    update_payment_details_description,
    title,
    subscription_status_title,
    monthly_subscription,
    trimonthly_subscription,
    annual_subscription,
    trialing,
    subscription_status_reseller_recurring_active,
    subscription_status_pending_cancellation,
    subscription_status_ended,
    subscription_status_active,
    subscription_section_title,
    restart_subscription,
    cancel_subscription,
    keep_subscription,
    redeem_offer_button_text,
    loading_process_text,
    personalize_your_workouts_title,
    personalize_your_workouts_description,
    payment_section_title,
    email_preferences_title,
    email_preferences_section_title,
    email_preferences_description,
    email_preferences_error_message,
    email_sent_message,
    double_opt_in_message,
    iap_subscriptions,
    billing_history,
    account_section_title,
    account_details_title,
    subscription,
    current_billing_detail,
    includes_tax,
    payment_details,
    what_happens_next,
    next_billing_detail,
    confirm_button_text,
    month,
    year,
    three_month,
    cancellation_success_popup_title,
    cancellation_success_popup_description,
    restart_subscription_description,
    close_button_label,
    cancel_subscription_generic_error,
    subscription_status_renewal,
  } = prismicAccountOverviewPage?.data as Queries.PrismicAccountOverviewPageData;

  const restartSubscriptionPopUpData = {
    subscription,
    restart_subscription,
    current_billing_detail,
    includes_tax,
    payment_details,
    what_happens_next,
    next_billing_detail,
    confirm_button_text,
    month,
    year,
    three_month,
    trimonthly_subscription,
    annual_subscription,
    monthly_subscription,
    subscription_status_title,
    update_payment_details_description,
    restart_subscription_success,
    restart_subscription_error,
  };

  const accountSectionData = {
    account_section_title,
    account_details_title,
    account_details_description,
    personalize_your_workouts_title,
    personalize_your_workouts_description,
  };

  const subscriptionSectionData = {
    subscription_status_title,
    monthly_subscription,
    trimonthly_subscription,
    annual_subscription,
    trialing,
    subscription_status_reseller_recurring_active,
    subscription_status_pending_cancellation,
    subscription_status_ended,
    subscription_status_active,
    subscription_section_title,
    restart_subscription,
    cancel_subscription,
    signup_channel,
    iap_subscriptions,
    restart_subscription_pending_cancellation,
    restart_subscription_cancellation,
    subscription_status_on_hold,
    subscription_status_renewal,
  };

  const paymentSectionData = {
    update_payment_details_title,
    update_payment_details_description,
    payment_section_title,
    billing_history,
    signup_channel,
    iap_subscriptions,
  };

  const emailPreferencesSectionData = {
    email_preferences_title,
    email_preferences_section_title,
    email_preferences_description,
    email_preferences_error_message,
    double_opt_in_message,
    email_sent_message,
  };

  const cancellaitonModalData = {
    keep_subscription,
    redeem_offer_button_text,
    loading_process_text,
  };

  const cancellaitonSuccessModalData = {
    cancellation_success_popup_title,
    cancellation_success_popup_description,
    restart_subscription_description,
    close_button_label,
  };

  const {
    hasValidOffer,
    countryAvailable: allowMemberPromoFullJourney,
    offer,
  } = useValidateMemberPromo({
    subscription: lmodSubscription,
    tier: tier as Tier,
    prismicData: prismicMemberPromoPopup?.data
      ?.first_offer as Queries.PrismicMemberPromoPopupDataFirstOfferItem[],
    addressCountry,
  });

  const handleDisplayMemberPromoModal = () => {
    if (allowMemberPromoFullJourney) {
      setIsCancellationModalOpened(false);
      setIsMemberPromoModalOpened(true);
    }
  };

  const handleRedeemOffer = () => {
    setLocalStorage(ALREADY_SEEN_MEMBER_PROMO_OFFER, 'true');
    navigate(`${ROUTES().OFFER_REDEMPTION}?isFrom=cancellation&promoCode=${offer?.promo_code}`);
  };

  const handleRestartSubscriptionPopUpClose = () => {
    setIsRestartSubscriptionPopUpOpened(false);
  };

  const handleRestartSubscriptionPopUpOpen = useCallback(() => {
    setIsRestartSubscriptionPopUpOpened(true);
  }, []);

  // when it's canceled state redirect to select plan page
  // when it's on-delayed-canceled state display restart popup
  const handleRestartSubscription = useCallback(() => {
    if (isFullyCanceled(state)) {
      navigate('/signup/select-plan');
    } else if (isPendingCancellation(state)) {
      handleRestartSubscriptionPopUpOpen();
    }
  }, [state, handleRestartSubscriptionPopUpOpen]);

  const { notification, setNotificationMessage, clearNotificationMessage } = useAccountNotification(
    {
      signupChannelList: signup_channel as unknown as SignupChannel[],
      prismicData: prismicAccountOverviewPage?.data as Queries.PrismicAccountOverviewPageData,
      handleRestartSubscription,
    }
  );

  return (
    <SubscriptionManagementLayout
      title={title}
      theme="white"
      backgroundColor={themes.white.colors.surfacePrimary}
      omitFooter
      scripts={customerIOScript}
      metaData={{
        title: 'Account Overview',
      }}
    >
      <div id="customerioPromoMessage" style={{ width: '100%' }} />
      {notification && (
        <Alert
          severity={notification.severity}
          style={{ marginBottom: '20px' }}
          onClose={clearNotificationMessage}
        >
          {notification.message}
        </Alert>
      )}
      {displayCancelSubscriptionError && (
        <Alert
          severity="error"
          style={{ marginBottom: '20px' }}
          onClose={() => {
            setDisplayCancelSubscriptionError(false);
          }}
        >
          <RichTextWrapper>
            <RichText
              render={cancel_subscription_generic_error?.richText}
              key={cancel_subscription_generic_error.text}
            />
          </RichTextWrapper>
        </Alert>
      )}

      {!isSubscriptionFailed(lmodSubscription?.state) && !error && (
        <>
          <SectionWrapper>
            <AccountSection data={accountSectionData} />
            <SubscriptionSection
              data={subscriptionSectionData}
              handleRestartSubscription={handleRestartSubscription}
              handleCancelSubscription={() => setIsCancellationModalOpened(true)}
            />
            <PaymentSection data={paymentSectionData} />
            <EmailPreferencesSection
              data={emailPreferencesSectionData}
              locationState={location.state}
            />
          </SectionWrapper>

          <RestartSubscriptionPopUp
            data={restartSubscriptionPopUpData}
            isOpened={isRestartSubscrionPopUpOpened}
            handleClose={handleRestartSubscriptionPopUpClose}
            handleNotification={setNotificationMessage}
          />

          <CancellationModal
            prismicData={cancellaitonModalData}
            isOpened={isCancellationModalOpened}
            handleClose={() => setIsCancellationModalOpened(false)}
            displayOfferButton={hasValidOffer}
            handleDisplayMemberPromoModal={handleDisplayMemberPromoModal}
            handleReedemOffer={handleRedeemOffer}
            allowMemberPromoFullJourney={!!allowMemberPromoFullJourney}
            handleOpenCancellationSuccessModal={() => setIsCancellationSuccessModal(true)}
            setCancelSubscriptionError={(isErr) => setDisplayCancelSubscriptionError(isErr)}
          />

          <CancellationSuccessModal
            isOpened={isCancellationSuccessModal}
            handleClose={() => setIsCancellationSuccessModal(false)}
            handleOpenRestartSubscription={() => {
              setIsRestartSubscriptionPopUpOpened(true);
            }}
            prismicData={cancellaitonSuccessModalData}
          />

          {hasValidOffer && isMemberPromoModalOpened && (
            <MemberPromoModal
              prismicData={prismicMemberPromoPopup?.data as Queries.PrismicMemberPromoPopupData}
              isOpened={isMemberPromoModalOpened}
              handleClose={() => {
                setIsMemberPromoModalOpened(false);
              }}
              handleOpenCancellationSuccessModal={() => setIsCancellationSuccessModal(true)}
              setCancelSubscriptionError={(isErr) => setDisplayCancelSubscriptionError(isErr)}
            />
          )}
        </>
      )}
    </SubscriptionManagementLayout>
  );
};

export default AccountOverview;
